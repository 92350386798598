<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Proveedores</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Pago a Provedores</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>ADMINISTRAR PAGO A PROVEEDORES</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-2">
            <label for="sucursal"><strong>SUCURSAL:</strong></label>
            <Dropdown
              v-model="sucursalSelected"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-4">
            <label for="proveedor_id"><strong>PROVEEDOR:</strong></label>
            <Dropdown
              v-model="proveedorSelected"
              :options="proveedores"
              optionLabel="nombre_completo"
              optionValue="id"
              :filter="true"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-3">
            <label for=""><strong>TIPO DE PAGO: </strong></label>
            <Dropdown
              v-model="tipoPagoSelected"
              :options="tipoPagos"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-3">
            <label for=""><strong>ESTADO DE PAGO: </strong></label>
            <Dropdown
              v-model="estadoPagoSelected"
              :options="estadoPagos"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-3">
            <label for=""><strong>FECHA INICIO: </strong></label>
            <InputText
              v-model="fecha_inicio"
              placeholder="Fecha Inicio"
              type="date"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for=""><strong>FECHA FIN: </strong></label>
            <InputText
              v-model="fecha_fin"
              placeholder="Fecha Fin"
              type="date"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="fecha_vencimiento_hasta"><strong>ACCIONES: </strong></label>
            <Button
              label="APLICAR FILTROS"
              icon="pi pi-search"
              class="p-button-primary mr-2"
              v-tooltip.top="'Aplicar los filtros'"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="pago_proveedores"
        key="id"
        :value="pago_proveedores"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm"
        :filters="buscar"
        responsive="true"
        stripedRows
        showGridlines
        :rows="10"
        :paginator="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 100, 500, 1000, 5000, 10000]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Pago a Proveedores"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Pago a Proveedores..."
              />
            </span>
          </div>
        </template>
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="p-invalid">
            Debe aplicar filtros para ver resultados en la tabla!</span
          >
        </template>
        <Column field="oc_recepcion_id" header="OC_ID"></Column>
        <Column field="nro_recibo" header="RECIBO FACTURA" :sortable="true">
          <template #body="{ data }">
            <div>
              {{ data.nro_recibo ? data.nro_recibo : "No Registrado"}}
            </div>
          </template>
        </Column>
        <Column field="nombre_proveedor" header="PROVEEDOR"></Column>
        <Column field="tipo_pago_id" header="TIPO PAGO">
          <template #body="{ data }">
            <div>
              {{ tipoPagoTexto(data.tipo_pago_id) }}
            </div>
          </template>
        </Column>
        <Column field="fecha_pago" header="FECHA PAGO" :sortable="true"></Column>
        <Column class="text-right" field="monto_total" header="MONTO TOTAL">
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.monto_total) }}
            </div>
          </template>
        </Column>
        <Column class="text-right" field="a_cuenta" header="A CUENTA">
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.a_cuenta) }}
            </div>
          </template>
        </Column>
        <Column class="text-right" field="saldo" header="SALDO">
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.saldo) }}
            </div>
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ estadoTexto(data.estado_texto) }}
            </span>
          </template>
        </Column>
        <Column field="observacion" header="OBSERVACION"></Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              icon="pi pi-ellipsis-v"
              v-tooltip.top="'Ver Acciones'"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
      <PagoProveedor
        :show="pagoProveedorDialog"
        :pago_proveedor="{ ...pago_proveedor }"
        @closeModal="cerrarModalPagoProveedor"
        @actualizarListado="actualizarListado"
      />
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProveedorService from "@/service/ProveedorService";
import SucursalService from "@/service/SucursalService";
import PagoProveedor from "@/module/proveedores/PagoProveedor.vue";
import OrdenCompraService from "@/service/OrdenCompraService";

export default {
  components: {
    PagoProveedor,
  },
  data() {
    return {
      pago_proveedores: [],
      pago_proveedor: {},
      sucursales: [{ id: 0, nombre: "TODAS" }],
      sucursalSelected: 0,
      proveedores: [{ id: 0, nombre_completo: "TODOS" }],
      proveedorSelected: 0,
      fecha_inicio: null,
      fecha_fin: null,
      enviando: false,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      tipoPagoSelected: 0,
      tipoPagos: [
        { id: 0, nombre: "TODOS" },
        { id: 1, nombre: "CONTADO" },
        { id: 2, nombre: "CRÉDITO" },
      ],
      estadoPagoSelected: 0,
      estadoPagos: [
        { id: 0, nombre: "TODOS" },
        { id: 2, nombre: "PENDIENTE" },
        { id: 1, nombre: "PAGADO" },
      ],
      pagoProveedorDialog: false,
      claseEstado: ["","status-instock", "status-outofstock", "status-instock"],
    };
  },
  proveedorSerice: null,
  sucursalService: null,
  ordenesCompras: null,
  created() {
    this.proveedorSerice = new ProveedorService();
    this.sucursalService = new SucursalService();
    this.ordenesCompras = new OrdenCompraService();
  },
  mounted() {
    this.cargarSucursales();
    this.cargarProveedores();
  },
  methods: {
    estadoTexto(estado) {
      if (estado == 1) {
        return "Pagado";
      } else {
        return "Pendiente";
      }
    },
    actualizarListado() {
      this.BuscarFiltro();
    },
    acciones(datos) {
      return [
        {
          label: "Registrar Pago",
          disabled: datos.estado == 1 ? true : false,
          /* visible: "Pago Proveedor Crear" in this.auth.user.permissions ? true : false, */
          icon: "pi pi-plus",
          command: () => {
            this.NuevoPagoProveedor(datos);
          },
        },

        {
          label: "Ver Detalle de Pago",
          icon: "pi pi-eye",
          to: "pagos_proveedor/" + datos.id + "/detalle",
        },
        {
          label: "Imprimir Orden de Compra",
          icon: "pi pi-print",
          command: () => {
            this.imprimirOC(datos.id);
          },
        },
      ];
    },
    imprimirOC(id) {
      this.ordenesCompras
        .imprimirOC(id)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    NuevoPagoProveedor(datos) {
      this.pago_proveedor = { ...datos };
      this.pagoProveedorDialog = true;
      this.pago_proveedor.fecha_pago = new Date();
      this.pago_proveedor.monto = 0;
      this.pago_proveedor.observacion = "";
    },
    cerrarModalPagoProveedor() {
      this.pagoProveedorDialog = false;
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE").format(numero);
    },
    BuscarFiltro() {
      this.pago_proveedores = [];
      this.enviando = true;
      this.cargarPagoProveedores();
    },
    cargarPagoProveedores() {
      let datos = {
        sucursal_id: this.sucursalSelected,
        proveedor_id: this.proveedorSelected,
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
        tipo_pago_id: this.tipoPagoSelected,
        estado: this.estadoPagoSelected,
      };
      this.proveedorSerice
        .cargarPagoProveedores(datos)
        .then((response) => {
          this.pago_proveedores = response.pago_proveedores;
          this.enviando = false;
        })
        .catch(() => {
          this.enviando = false;
        });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          response.forEach((element) => {
            this.sucursales.push(element);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarProveedores() {
      this.proveedorSerice
        .getProveedores()
        .then((response) => {
          this.proveedores = response.proveedores;
          this.proveedores.unshift({ id: 0, nombre_completo: "Todos" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    tipoPagoTexto(tipo_pago) {
      switch (tipo_pago) {
        case 1:
          return "CONTADO";
        case 2:
          return "CRÉDITO";
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-fondo {
  background-color: #7bffae;
}
</style>
